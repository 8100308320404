import { Component, OnInit, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { firstValueFrom } from "rxjs";

import { Constants } from "../../../../constants/constants";
import { AwsAccountsService } from "../aws-accounts.service";
import { TitleService } from "../../../../services/title.service";
import { map, take } from "rxjs/operators";
import { FormBuilder, Validators } from "@angular/forms";

@Component({
    selector: "app-aws-account-form",
    templateUrl: "./aws-account-form.component.html"
})
export class AwsAccountFormComponent implements OnInit {
    private accountId: number;
    submitted = false;
    saving = false;
    isEdit: boolean;
    loading = true;
    constants = Constants;

    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private ts = inject(AwsAccountsService);
    private titleService = inject(TitleService);
    form = inject(FormBuilder).group({
        name: ["", [Validators.required, Validators.minLength(2), Validators.pattern(Constants.validators.name)]],
        iam_role_arn: ["", [Validators.required, Validators.pattern(Constants.validators.iam_role_arn)]]
    });

    awsAccountNames$ = this.ts.awsAccounts.pipe(
        map(awsAccounts => {
            let names = awsAccounts.map(t => t.name);
            if (this.isEdit) names = awsAccounts.filter(t => t.id !== this.accountId).map(t => t.name);
            return names;
        })
    );

    private async prepForm() {
        if (this.accountId) {
            const accountDetails = await firstValueFrom(
                this.ts.awsAccounts.pipe(
                    take(1),
                    map(awsAccounts => awsAccounts.find(t => t.id === this.accountId))
                )
            );
            this.form.patchValue(accountDetails);
        } else {
            this.form.reset();
        }
    }

    async ngOnInit() {
        const params = this.route.snapshot.params;
        this.accountId = parseInt(params.id, 10);
        const action = params.action;
        this.isEdit = action === "edit";

        await firstValueFrom(this.ts.refreshAwsAccounts());

        await this.prepForm();

        // Set Title
        this.titleService.setTitle("AWS_ACCOUNT", action, this.form.value.name);
        this.loading = false;
    }

    async onSubmit() {
        this.saving = true;

        const model = this.form.getRawValue();

        const result = this.isEdit
            ? await this.ts.updateAwsAccount(this.accountId, model)
            : await this.ts.addAwsAccount(model);

        if (result) this.router.navigate([Constants.urls.configuration.aws]);
        this.saving = false;
    }
}
