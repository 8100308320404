<app-form-wrapper titleMsg="AWS_ACCOUNT" [formGroup]="form" [loading]="loading" [isEdit]="isEdit">
    <!-- Name -->
    <app-input-generic-field
        [formControl]="form.controls.name"
        [textFieldName]="'NAME' | translate"
        type="text"
        [isParentFormSubmitted]="submitted"
        [checkDuplicateNames]="awsAccountNames$ | async"
    ></app-input-generic-field>

    <!-- ARN -->
    <app-input-generic-field
        type="text"
        [formControl]="form.controls.iam_role_arn"
        textFieldName="{{ 'ROLE' | translate }} ARN"
        underLabel="{{ 'IAM_ROLE_EXAMPLE' | translate }}"
        [isParentFormSubmitted]="submitted"
        patternErrorMessage="{{ 'IAM_ROLE_PATTERN_ERROR' | translate }}"
    ></app-input-generic-field>

    <app-error [marginBottom]="true" [marginTop]="false"></app-error>

    <app-zx-form-bottom-buttons
        [isFormValid]="form.valid"
        [(submitted)]="submitted"
        (submit)="onSubmit()"
        [isHorizontalRowNeeded]="true"
        [cancelUrlNavigate]="constants.urls.configuration.aws"
        [isSaving]="saving"
        [isEdit]="isEdit"
    ></app-zx-form-bottom-buttons>
</app-form-wrapper>
